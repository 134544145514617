import React from 'react';
import { withTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/App.css';
import Header from "../components/Header";
import LogoWeb from '../img/logoweb.png';
import VisaMaster from '../img/visa-master.png';
import QRPayment from '../img/qr_payment.jpg';
import Axios from 'axios';
import moment from "moment";
import SweetAlert from 'react-bootstrap-sweetalert';

function selectMonth() {
  let nowDate = new Date().toISOString();
  let nowYear = nowDate.substring(0,4);

  let selectOption = [];

  for (let i = nowYear; i < parseInt(nowYear)+50; i++) {
    selectOption.push(<option value={i} key={i}>{i}</option>)
  }

  return selectOption;
}

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overdueAmount : 0,
      isSubmitCreditPayment: false,
      isAlertConfirmCreditPayment: false
    };
  }

  componentDidMount() {
    Axios.get(process.env.REACT_APP_PAYMENT_API_URL+'/api/v1/verify/overdue/'+this.props.match.params.id)
      .then((res) => {
        res.data.data.customer.paymentTypeDetail.forEach((value) => {
          if (value.type === 'Remain' && value.amount > 0) {
            this.setState({ overdueAmount: value.amount, tripDetail: res.data.data })
          }
        });
    })
      .catch(() => {
        document.body.classList.remove('loading-indicator');
    });

  }

  currencyFormat = (num) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  };

  handlePaymentOption = (event) => {
    this.setState({ paymentType: event.target.value })
  };

  handleMonthOption = (event) => {
    this.setState({ selectMonth : event.target.value }, this.handleCreditPayment)
  };

  handleYearOption = (event) => {
    this.setState({ selectYear : event.target.value }, this.handleCreditPayment)
  };

  handleCreditPayment = () => {
    if (this.state.cardholder_name && this.state.card_number
      && this.state.selectMonth && this.state.selectYear
      && this.state.cvv
    ) {
      this.setState({ isSubmitCreditPayment: true })
    } else {
      this.setState({ isSubmitCreditPayment: false })
    }
  };

  handleShowAlertCreditPayment() {
    this.setState({ isAlertConfirmCreditPayment: true })
  }

  handleHideAlertCreditPayment() {
    this.setState({ isAlertConfirmCreditPayment: false })
  }

  handleConfirmCreditPayment() {
    this.handleHideAlertCreditPayment();

    Axios.post(process.env.REACT_APP_PAYMENT_API_URL+'/api/v1/payment/overdue/credit/'+this.props.match.params.id, {
      name: this.state.cardholder_name,
      card_number: this.state.card_number,
      expiration_month: this.state.selectMonth,
      expiration_year: this.state.selectYear,
      security_code: this.state.cvv
    }, {
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.history.push('/billing/status', { status: "success" })
        } else {
          this.props.history.push('/billing/status', { status: "fail" })
        }
      })
      .catch(() => {
        this.props.history.push('/billing/status', { status: "fail" })
      })
  }

  handleConfirmTransfer() {
    this.props.history.push('/billing/transfer-confirmation', { id: this.props.match.params.id })
  }

  render() {
    const { t } = this.props;

    return(
      <div>
        <div className="container" style={{ color:"#99aabb" }}>
          <div className="row justify-content-center">
            <div className="col-md-10 text-right">
              <Header/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <img src={LogoWeb} alt={"Logo Web"}/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <h5 className="font-weight-bold">{ t('payment.title_detail') }</h5>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-md-10 p-5 mb-5" style={{ backgroundColor: "black" }}>

              { this.state.overdueAmount > 0 ? (
                <div>
                  <div className="row font-weight-bold">
                    <div className="col-md-12">{ t('payment.trip_no') } : <span className="primary-color">{ this.state.tripDetail._id.substring(0,5).toUpperCase() }</span></div>
                    <div className="col-md-12">{ t('payment.amount') } : <span className="primary-color">{ this.currencyFormat(this.state.overdueAmount) } { t('payment.currency') }</span></div>
                    <div className="col-md-12">{ t('payment.service_date') } : <span className="primary-color">{ moment(this.state.tripDetail.pickupDate).format("DD-MM-YYYY HH:mm") }</span></div>
                    <div className="col-md-12">{ t('payment.origin') } : <span className="primary-color">{ this.state.tripDetail.fromLocation.name }</span></div>
                    <div className="col-md-12">{ t('payment.destination') } : <span className="primary-color">{ this.state.tripDetail.toLocation.name }</span></div>
                  </div>

                  <div className="mt-5">
                    <select className="custom-select" onChange={this.handlePaymentOption}>
                      <option value="" defaultValue>{ t('payment.type') }</option>
                      <option value="credit_card">{ t('payment.credit_card') }</option>
                      <option value="banking">{ t('payment.banking') }</option>
                    </select>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="col-md-12 text-center font-weight-bold">
                    { t('payment.record_not_found') }
                  </div>
                </div>
              ) }

              { this.state.paymentType === 'credit_card' && (
                <div className="mt-5">
                  <div className="row">
                    <div className="col">
                      <img src={VisaMaster} alt="visa-master" style={{ width:120 }}/>
                    </div>
                  </div>
                  <form>
                    <div className="row mt-3">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={ t('payment.cardholder_name') }
                          autoComplete={"false"}
                          value={ this.state.cardholder_name || '' }
                          onChange={ (e) => {
                            this.setState({ cardholder_name : e.target.value }, this.handleCreditPayment)
                          }}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={ t('payment.card_number') }
                          autoComplete="false"
                          value={ this.state.card_number || '' }
                          onChange={(e) => {
                            let regex = /^[0-9\b]+$/;
                            if (regex.test(e.target.value) || e.target.value === '') {
                              if (e.target.value.length <= 16) {
                                this.setState({ card_number: e.target.value }, this.handleCreditPayment);
                              }
                            } else {
                              alert(t('payment.alert_number_only'))
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col">
                        <select className="custom-select" onChange={this.handleMonthOption}>
                          <option value="" defaultValue>{ t('payment.month') }</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <div className="col">
                        <select className="custom-select" onChange={this.handleYearOption}>
                          <option value="" defaultValue>{ t('payment.year') }</option>
                          { selectMonth() }
                        </select>
                      </div>
                      <div className="col">
                        <input
                          type="password"
                          name="cvv"
                          className="form-control"
                          placeholder="CVV/CVV2"
                          autoComplete={"false"}
                          value={ this.state.cvv || '' }
                          onChange={(e) => {
                            let regex = /^[0-9\b]+$/;
                            if (regex.test(e.target.value) || e.target.value === '') {
                              if (e.target.value.length <= 3) {
                                this.setState({ cvv: e.target.value }, this.handleCreditPayment);
                              }
                            } else {
                              alert(t('payment.alert_number_only'))
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mt-5 justify-content-center">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-success btn-block"
                          disabled={!this.state.isSubmitCreditPayment}
                          onClick={() => { this.handleShowAlertCreditPayment() }}
                        >
                          { t('payment.confirm_button') }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) }

              { this.state.paymentType === 'banking' && (
                <div className="mt-5">
                  <div className="row">
                    <div className="col text-center">
                      <img src={QRPayment} alt={"QR Payment"} style={{ width: "30%" }}/>
                    </div>
                  </div>

                  <div className="row mt-5 justify-content-center">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={() => { this.handleConfirmTransfer() }}
                      >
                        { t('payment.text_transfer_confirmation') }
                      </button>
                    </div>
                  </div>
                </div>
              ) }
            </div>
          </div>
        </div>

        <SweetAlert
          show={this.state.isAlertConfirmCreditPayment}
          custom
          showCancel
          showCloseButton
          confirmBtnText={ t('payment.text_alert_confirm_button') }
          cancelBtnText={ t('payment.text_alert_cancel_button') }
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title={ t('payment.text_alert_title') }
          onConfirm={this.handleConfirmCreditPayment.bind(this)}
          onCancel={this.handleHideAlertCreditPayment.bind(this)}
        >
        </SweetAlert>
      </div>
    )
  }
}

export default withTranslation()(Payment);
