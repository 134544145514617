import React from 'react';
import { useTranslation } from 'react-i18next';
import ENFlag  from '../img/en-flag.png';
import THFlag  from '../img/th-flag.png';

export default function Header() {
  let { i18n } = useTranslation();

  return(
    <div className="mt-5">
      <img
        className="mr-2"
        style={{ cursor: "pointer" }}
        src={ENFlag} alt={"English Flag"}
        onClick={() => i18n.changeLanguage('en')}
      />
      <img
        style={{ cursor: "pointer" }}
        src={THFlag} alt={"Thai Flag"}
        onClick={() => i18n.changeLanguage('th')}
      />
    </div>
  )
}
