import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/App.css';

export default function () {
  return(
    <div className="container">
      <div className="row text-light">
        <div className="col-12 text-center" style={{ marginTop:"30%" }}>
        <span style={{ fontSize: 24, fontWeight:"bold"}}>
          PAGE NOT FOUND.
        </span>
        </div>
      </div>
    </div>
  )
}
