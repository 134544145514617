import React from 'react';
import { withTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/App.css';
import Header from "../components/Header";
import LogoWeb from '../img/logoweb.png';

class PaymentStatus extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.location.state) {
      this.props.history.push('/')
    }

    document.body.classList.remove('loading-indicator');
  }

  render() {
    const { t } = this.props;

    return(
      <div>
        <div className="container" style={{ color:"#99aabb" }}>
          <div className="row justify-content-center">
            <div className="col-md-10 text-right">
              <Header/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <img src={LogoWeb} alt={"Logo Web"}/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <h5 className="font-weight-bold">{ t('payment.title_detail') }</h5>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            { this.props.location.state && this.props.location.state.status === 'success' ? (
              <div className="col-md-10 p-5 mb-5 text-success font-weight-bold text-center" style={{ backgroundColor: "black" }}>
                <h5>{ t('payment.text_payment_successful') }</h5>
              </div>
            ) : (
              <div className="col-md-10 p-5 mb-5 text-danger font-weight-bold text-center" style={{ backgroundColor: "black" }}>
                <h5>{ t('payment.text_payment_fail') }</h5>
              </div>
            ) }
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PaymentStatus);
