import React from 'react'
import Header from "../components/Header";
import LogoWeb from "../img/logoweb.png";
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import FileBase64 from 'react-file-base64';
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert'

const recaptchaRef = React.createRef();

class TransferConfirmation extends React.Component{
  constructor(props) {
    super(props)

    if (!this.props.location.state) {
      this.props.history.push('/')
    }

    this.state = {
      isSubmitTransfer: false,
      alertUploadStatus: false,
      uploadStatus: false
    }
  }



  handleFileUpload(files) {
    this.setState({ files: files }, this.handleInputFormChange.bind(this))
  }

  handleReCaptchaOnchange(value) {
    this.setState({ captcha:value })
  }

  handleInputFormChange() {
    if (this.state.amount && this.state.dateTime && this.state.files) {
      this.setState({ isSubmitTransfer: true })
      recaptchaRef.current.execute();
    } else {
      this.setState({ isSubmitTransfer: false })
    }
  }

  handleSubmit() {
    Axios.post(process.env.REACT_APP_BILLING_API_URL+'/api/v1/payment/transfer/slip', {
      booking_id: this.props.location.state.id,
      amount: this.state.amount,
      date_time: this.state.dateTime,
      base64img: this.state.files.base64.replace(/^data:image\/\w+;base64,/, ""),
      recaptcha_key: this.state.captcha
    }, {
      headers: {
        'content-type': 'application/json'
      }
    }).then((response) => {
      if (!response.data.success) {
        this.setState({ uploadStatus: false })
      } else {
        this.setState({ uploadStatus: true})
      }

      this.setState({ alertUploadStatus: true })
    })
  }

  render() {
    const { t } = this.props;

    return(
      <div>
        <div className="container" style={{ color:"#99aabb" }}>
          <div className="row justify-content-center">
            <div className="col-md-10 text-right">
              <Header/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <img src={LogoWeb} alt={"Logo Web"}/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <h5 className="font-weight-bold">{ t('payment.text_transfer_confirmation') }</h5>
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-md-10 p-5 mb-5" style={{ backgroundColor: "black" }}>

              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={ t('payment.text_transfer_amount') }
                    autoComplete={"false"}
                    value={ this.state.amount || '' }
                    onChange={(e) => {
                      let regex = /^[0-9.\b]+$/;
                      if (regex.test(e.target.value) || e.target.value === '') {
                        this.setState({ amount: e.target.value }, this.handleInputFormChange.bind(this));
                      } else {
                        alert(t('payment.alert_number_only'))
                      }
                    }}
                  />
                </div>
                <div className="col">
                  <InputMask
                    className="form-control"
                    mask="9999-99-99 99:99"
                    value={ this.state.dateTime || '' }
                    onChange={(event) => {
                      this.setState({dateTime: event.target.value}, this.handleInputFormChange.bind(this));
                    }}
                    placeholder={t("payment.text_transfer_datetime")}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <FileBase64
                    multiple={ false }
                    onClick={() => {
                      this.setState({ files: '' })
                    }}
                    onDone={ this.handleFileUpload.bind(this) } />
                </div>

                <div className="col">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={"6LeN2LgUAAAAACEm7P1tH_-VStjDayPFCGmk2rwX"}
                    size="invisible"
                    onChange={this.handleReCaptchaOnchange.bind(this)}
                  />
                </div>
              </div>

              <div className="row mt-5 justify-content-center">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    disabled={!this.state.isSubmitTransfer}
                    onClick={() => { this.handleSubmit() }}
                  >
                    { t('payment.text_confirm_button') }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        { !this.state.uploadStatus ? (
          <SweetAlert
            show={this.state.alertUploadStatus}
            danger
            title="Upload status!"
            onConfirm={() => {
              this.setState({ alertUploadStatus : false })
            }}
          >
            { t('payment.text_transfer_upload_fail') }
          </SweetAlert>
        ) : (
          <SweetAlert
            show={this.state.alertUploadStatus}
            success
            title="Upload status!"
            onConfirm={() => {
              this.props.history.replace({});
              window.location.href = 'https://udrinkidrive.co.th'
            }}
          >
            { t('payment.text_transfer_upload_success') }
          </SweetAlert>
        ) }
      </div>
    )
  }
}

export default withTranslation()(TransferConfirmation)
