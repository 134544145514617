import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Payment from './Payment';
import PageNotFound from './404';
import PaymentStatus from "./PaymentStatus";
import TransferConfirmation from "./TransferConfirmation"
import Index from "./Index";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Index}/>
        <Route path="/billing/status" exact component={PaymentStatus} />
        <Route path="/billing/transfer-confirmation" exact component={TransferConfirmation} />
        <Route path="/billing/:id" exact component={Payment} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  )
}
